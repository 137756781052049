<template>
    <div class="footer-intro-wrapper">
        <div class="footer-logo">
            <router-link to="/">
                <img :src="logo" class="img-fluid" style="height:100px;width:150px" alt="logo">
            </router-link>
        </div>
        <div class="footer-desc" style="font-size:20px">
            {{ desc }}
        </div>
    </div>
</template>

<script>
    export default {
        props: [ 'logo', 'desc' ]
    };
</script>